<template>
  <div class="px-5 pt-5">
    <v-select
      v-model="moratoriumsSelected"
      chips
      filleds
      multiple
      label="Meses de moratoria"
      :item-text="(item) => `${item.month}/${item.year}`"
      :items="items"
      append-outer-icon="save"
      return-object
      @input="sortArrayDates(moratoriumsSelected)"
      @click:append-outer="editProjectMoratoriums()"
    />
  </div>
</template>

<script>
import CommonMixin from "@/mixins/CommonMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";

export default {
  mixins: [CommonMixin, FormatDateMixin],
  props: {
    project: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      moratoriumsSelected: []
    };
  },
  created() {
    if (this.project.moratoriums) this.moratoriumsSelected = this.sortArrayDates([...this.project.moratoriums]);
  },
  watch: {
    "project.moratoriums"() {
      this.moratoriumsSelected = this.sortArrayDates([...this.project.moratoriums]);
    }
  },
  computed: {
    items() {
      const firstPayment = this.project.financial_product.conditions.waiting_period + 1;
      const lastPayment = firstPayment + this.project.financial_product.conditions.monthly_fees;
      let items = this.project.moratoriums ? [...this.project.moratoriums] : [];

      for (let i = firstPayment; i < lastPayment; i++) {
        const element = this.project.calendar_payments.payments[i];
        const [year, month] = element.due_date.split("-");

        items.push({
          month: parseInt(month),
          year: parseInt(year)
        });
      }

      return this.sortArrayDates(items);
    }
  },
  methods: {
    async editProjectMoratoriums() {
      await this.$store.dispatch(`projects/${this.action}`, { id: this.project.id, moratoriums: this.moratoriumsSelected });
      this.moratoriumsSelected = this.project.moratoriums ? this.sortArrayDates(this.project.moratoriums) : [];
    }
  }
};
</script>
