<template>
  <div>
    <v-form ref="formSearchByDate" v-model="formSearchByDateValid" class="date-picker d-flex align-center">
      <DatePicker
        :parent_model="datePicker"
        label="Fecha"
        :required="true"
        :min="dateNowFormated()"
        :max="formatDate(maxDate)"
        :clearable="false"
        @change="datePicker = $event"
      />
      <v-btn fab x-small class="ml-5" @click="searchAmountByDate()">
        <v-icon>search</v-icon>
      </v-btn>
    </v-form>
    <p><b>Cantidad amortización total: </b>{{ prepaidAmountInfo.amount }}</p>
    <p><b>Coeficiente liquidación anticipada: </b>{{ this.round(prepaidAmountInfo.irr) }}</p>
  </div>
</template>

<script>
import DatePicker from "@/components/elements/DatePicker";
import CommonMixin from "@/mixins/CommonMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import * as currency from "currency.js";

export default {
  components: { DatePicker },
  mixins: [CommonMixin, FormatDateMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    maxDate: {
      type: String,
      required: true
    },
    actionName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      prepaidAmountInfo: {},

      // Search by date form
      formSearchByDateValid: false,
      datePicker: ""
    };
  },
  computed: {
    currency() {
      return currency;
    },
    nameId() {
      switch (this.actionName) {
        case "getAmountPrepaidProject":
          return "project_id";
        case "getAmountPrepaidTestCalendarPayments":
          return "id";

        default:
          return "";
      }
    }
  },
  async created() {
    this.datePicker = this.dateNowFormated();
    this.prepaidAmountInfo = await this.$store.dispatch(`projects/${this.actionName}`, { [this.nameId]: this.id });
  },
  methods: {
    async searchAmountByDate() {
      if (this.$refs.formSearchByDate.validate() && this.datePicker != this.dateNowFormated()) {
        const dateToISO = this.formatDateToISO8601(this.datePicker);
        this.prepaidAmountInfo = await this.$store.dispatch(`projects/${this.actionName}`, {
          [this.nameId]: this.id,
          date: dateToISO
        });
      }
    }
  }
};
</script>

<style scoped>
.date-picker {
  max-width: 350px;
}
</style>
