<template>
  <v-simple-table fixed-header dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th><b>Estado</b></th>
          <th v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.status }}
          </th>
        </tr>
        <tr>
          <th><b>Fecha</b></th>
          <th v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ formatDateL(item.due_date) }}
          </th>
        </tr>
        <tr>
          <th><b>Fecha + 15 días</b></th>
          <th v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ formatDateL(date15DaysAfter(item.due_date)) }}
          </th>
        </tr>
        <tr>
          <th><b>Nº de paquete</b></th>
          <th v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)" class="text-center">
            {{ i }}
          </th>
        </tr>
      </thead>

      <tbody v-if="checkIfCalendarExists('theoretical') && (!financialProductType || financialProductType === 'COOPERATIVE')">
        <tr>
          <td class="text-center black" colspan="100%"><span class="text-subtitle-1 white--text">TEÓRICO</span></td>
        </tr>
        <tr>
          <th><b>Comision contraparte</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.partner_commission }}
          </td>
        </tr>
        <tr>
          <th><b>Días de retraso</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.days_late }}
          </td>
        </tr>
        <tr>
          <th><b>IVA mora</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.arrear_vat }}
          </td>
        </tr>
        <tr>
          <th><b>Mora</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.theoretical.arrear }}</td>
        </tr>
        <tr>
          <th><b>IVA deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.debt_vat }}
          </td>
        </tr>
        <tr>
          <th><b>Intereses deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.debt_interest }}
          </td>
        </tr>
        <tr>
          <th><b>Capital deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.debt_capital }}
          </td>
        </tr>
        <tr>
          <th><b>IVA cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.current_vat }}
          </td>
        </tr>
        <tr>
          <th><b>Intereses cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.current_interest }}
          </td>
        </tr>
        <tr>
          <th><b>Capital cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.current_capital }}
          </td>
        </tr>
        <tr>
          <th><b>Cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.current_fee }}
          </td>
        </tr>
        <tr>
          <th><b>TOTAL PAGADO</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.theoretical.current_total }}
          </td>
        </tr>
        <tr>
          <th><b>Capital pendiente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.theoretical.total }}</td>
        </tr>
      </tbody>

      <!-- ------------------------------------------------------------------------------------------------------------- -->
      <tbody v-if="checkIfCalendarExists('real')">
        <tr>
          <td class="text-center black" colspan="100%"><span class="text-subtitle-1 white--text">REAL</span></td>
        </tr>
        <tr>
          <th><b>Comision contraparte</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.real.partner_commission }}
          </td>
        </tr>
        <tr>
          <th><b>Días de retraso</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.days_late }}</td>
        </tr>
        <tr>
          <th><b>IVA mora</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.arrear_vat }}</td>
        </tr>
        <tr>
          <th><b>Mora</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.arrear }}</td>
        </tr>
        <tr>
          <th><b>IVA deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.debt_vat }}</td>
        </tr>
        <tr>
          <th><b>Intereses deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.debt_interest }}</td>
        </tr>
        <tr>
          <th><b>Capital deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.debt_capital }}</td>
        </tr>
        <tr>
          <th><b>IVA cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.current_vat }}</td>
        </tr>
        <tr>
          <th><b>Intereses cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.real.current_interest }}
          </td>
        </tr>
        <tr>
          <th><b>Capital cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.real.current_capital }}
          </td>
        </tr>
        <tr>
          <th><b>Cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.current_fee }}</td>
        </tr>
        <tr>
          <th><b>TOTAL PAGADO</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.current_total }}</td>
        </tr>
        <tr>
          <th><b>Capital pendiente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.real.total }}</td>
        </tr>
      </tbody>

      <!-- ------------------------------------------------------------------------------------------------------------- -->
      <tbody v-if="checkIfCalendarExists('future') && (!financialProductType || financialProductType === 'COOPERATIVE')">
        <tr>
          <td class="text-center black" colspan="100%"><span class="text-subtitle-1 white--text">FUTURO</span></td>
        </tr>
        <tr>
          <th><b>Comision contraparte</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.future.partner_commission }}
          </td>
        </tr>
        <tr>
          <th><b>Días de retraso</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.days_late }}</td>
        </tr>
        <tr>
          <th><b>IVA mora</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.arrear_vat }}</td>
        </tr>
        <tr>
          <th><b>Mora</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.arrear }}</td>
        </tr>
        <tr>
          <th><b>IVA deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.debt_vat }}</td>
        </tr>
        <tr>
          <th><b>Intereses deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.future.debt_interest }}
          </td>
        </tr>
        <tr>
          <th><b>Capital deuda</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.debt_capital }}</td>
        </tr>
        <tr>
          <th><b>IVA cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.current_vat }}</td>
        </tr>
        <tr>
          <th><b>Intereses cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.future.current_interest }}
          </td>
        </tr>
        <tr>
          <th><b>Capital cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.future.current_capital }}
          </td>
        </tr>
        <tr>
          <th><b>Cuota corriente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.current_fee }}</td>
        </tr>
        <tr>
          <th><b>TOTAL PAGADO</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">
            {{ item.future.current_total }}
          </td>
        </tr>
        <tr>
          <th><b>Capital pendiente</b></th>
          <td v-for="(item, i) in calendarPayments.payments" :key="i" :class="isInPeriod(item)">{{ item.future.total }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import CommonMixin from "@/mixins/CommonMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";

export default {
  mixins: [CommonMixin, FormatDateMixin],
  props: {
    calendarPayments: {
      type: Object,
      required: true
    },
    financialProductType: {
      type: String,
      required: true
    }
  },
  methods: {
    isInPeriod(item) {
      return item.number === this.calendarPayments?.current_payment_number ? "bg-in-period" : "";
    },

    checkIfCalendarExists(calendarType) {
      return !!this.calendarPayments.payments.find((e) => e[calendarType]);
    }
  }
};
</script>

<style scoped>
* {
  font-size: 12px !important;
}

table > tbody td {
  border-right: 1px solid black;
}

table > thead > tr:nth-child(3) > th {
  top: 64px;
}

table > thead > tr:nth-child(4) > th {
  top: 96px;
}

thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background-color: rgb(218, 218, 218) !important;
  color: black !important;
}

thead th:first-child {
  left: 0;
  border-right: 1px solid black;
  background-color: rgb(218, 218, 218) !important;
  z-index: 3 !important;
}

tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  border-right: 1px solid black;
  background-color: rgb(218, 218, 218) !important;
}

.bg-in-period {
  background-color: rgb(1, 249, 173, 1) !important;
}
</style>
